#navigator {
  position: sticky;
  top: 0;
  z-index: 1000;
}

#navigator .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 720px) {
  #navigator {
    border-bottom: 1px solid #ccc;
  }
}

#navigator a {
  font-size: 14px;
}

#navigator + section {
  padding: 250px 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar {
  width: 20%;
  height: 800px;
  min-height: 500px;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.main {
  width: 80%;
  display: flex;
  flex-direction: column;
}

/*
.main,
.sidebar {
  border: 5px solid #000;
  background-color: #ccc;
  border-radius: 10px;
  color: #000;
  padding: 15px;
}

<section className="fdb-block" style={{ paddingTop: "10px" }}>
                <div className="col text-center">
                  {topic &&
                    topic.subtopics &&
                    topic.subtopics.map(subtopic => (
                      <SubtopicItem key={subtopic.subtopicId} subtopic={subtopic} />
                    ))}
                </div>
              </section>
*/

.wrapper {
  display: flex;
  justify-content: space-between;
}

.containerTags {
  justify-content: space-around;
  align-content: space-evenly;
}

.tag-outline-primary:hover {
  color: #007bff;
  background-color: #ffffff;
  border-width: "1px";
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

.tag-primary:hover {
  border-width: "1px";
  border-color: #007bff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}


.tag-outline-tags:hover {
  color: #E55D3D;
  background-color: #ffffff;
  border-width: "1px";
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

.tag-tags:hover {
  border-width: "1px";
  border-color: #E55D3D;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .h1-text {
    font-size: 50px;
  }
  .h3-text {
    font-size: 20px;
  }
  .brackets {
    font-size: 100px;
  }
  .brackets-text {
    font-size: 1.5rem;
  }
  .ang-text {
    font-size: 1.4rem;
  }
  .fun-text {
    font-size: 1.4rem;
  }

  .ang-brackets {
    font-size: 4rem;
  }

  .card-brackets {
    font-size: 1.7rem;
  }

  .card-font {
    font-size: 1rem;
  }

  .end-text {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.2rem;
    font-weight: 100;
  }
}

@media (min-width: 768px) {
  .h1-text {
    font-size: 60px;
  }
  .h3-text {
    font-size: 25px;
  }
  .brackets {
    font-size: 110px;
  }
  .brackets-text {
    font-size: 2rem;
  }
  .ang-text {
    font-size: 1.5rem;
  }
  .fun-text {
    font-size: 1.5rem;
  }

  .ang-brackets {
    font-size: 4rem;
  }

  .card-brackets {
    font-size: 1.7rem;
  }

  .card-font {
    font-size: 1rem;
  }

  .end-text {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.3rem;
    font-weight: 100;
  }
}

@media (min-width: 992px) {
  .h1-text {
    font-size: 70px;
  }
  .h3-text {
    font-size: 25px;
  }
  .brackets {
    font-size: 85px;
  }
  .brackets-text {
    font-size: 2rem;
  }
  .ang-text {
    font-size: 1.5rem;
  }
  .fun-text {
    font-size: 1.5rem;
  }

  .ang-brackets {
    font-size: 4rem;
  }
  .card-brackets {
    font-size: 2rem;
  }

  .card-font {
    font-size: 1rem;
  }

  .end-text {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.3rem;
    font-weight: 100;
  }
}

@media (min-width: 1200px) {
  .h1-text {
    font-size: 70px;
  }
  .h3-text {
    font-size: 30px;
  }
  .brackets {
    font-size: 90px;
  }
  .brackets-text {
    font-size: 2.5rem;
  }
  .ang-text {
    font-size: 1.7rem;
  }
  .fun-text {
    font-size: 1.6rem;
  }

  .ang-brackets {
    font-size: 2.5rem;
  }

  .card-brackets {
    font-size: 2rem;
  }

  .card-font {
    font-size: 1.2rem;
  }

  .end-text {
    font-size: 1.7rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.5rem;
    font-weight: 100;
  }

  .text-1 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  .text-2 {
    font-size: 1.5rem;
    font-weight: 100;

  }
}

.input:focus {
  outline: none !important;
  border:1px solid #E55D3D;
  box-shadow: 0 0 10px #E55D3D;
}

.btn-outline-tags {
    color: #fe6655;
    background-color: transparent;
    background-image: none;
    border-color: #fe6655;
}