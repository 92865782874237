@import url(https://fonts.googleapis.com/css?family=Raleway:200);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200);
@import url(https://fonts.googleapis.com/css?family=Raleway:200);
#navigator {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

#navigator .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 720px) {
  #navigator {
    border-bottom: 1px solid #ccc;
  }
}

#navigator a {
  font-size: 14px;
}

#navigator + section {
  padding: 250px 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar {
  width: 20%;
  height: 800px;
  min-height: 500px;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.main {
  width: 80%;
  display: flex;
  flex-direction: column;
}

/*
.main,
.sidebar {
  border: 5px solid #000;
  background-color: #ccc;
  border-radius: 10px;
  color: #000;
  padding: 15px;
}

<section className="fdb-block" style={{ paddingTop: "10px" }}>
                <div className="col text-center">
                  {topic &&
                    topic.subtopics &&
                    topic.subtopics.map(subtopic => (
                      <SubtopicItem key={subtopic.subtopicId} subtopic={subtopic} />
                    ))}
                </div>
              </section>
*/

.wrapper {
  display: flex;
  justify-content: space-between;
}

.containerTags {
  justify-content: space-around;
  align-content: space-evenly;
}

.tag-outline-primary:hover {
  color: #007bff;
  background-color: #ffffff;
  border-width: "1px";
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

.tag-primary:hover {
  border-width: "1px";
  border-color: #007bff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}


.tag-outline-tags:hover {
  color: #E55D3D;
  background-color: #ffffff;
  border-width: "1px";
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

.tag-tags:hover {
  border-width: "1px";
  border-color: #E55D3D;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .h1-text {
    font-size: 50px;
  }
  .h3-text {
    font-size: 20px;
  }
  .brackets {
    font-size: 100px;
  }
  .brackets-text {
    font-size: 1.5rem;
  }
  .ang-text {
    font-size: 1.4rem;
  }
  .fun-text {
    font-size: 1.4rem;
  }

  .ang-brackets {
    font-size: 4rem;
  }

  .card-brackets {
    font-size: 1.7rem;
  }

  .card-font {
    font-size: 1rem;
  }

  .end-text {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.2rem;
    font-weight: 100;
  }
}

@media (min-width: 768px) {
  .h1-text {
    font-size: 60px;
  }
  .h3-text {
    font-size: 25px;
  }
  .brackets {
    font-size: 110px;
  }
  .brackets-text {
    font-size: 2rem;
  }
  .ang-text {
    font-size: 1.5rem;
  }
  .fun-text {
    font-size: 1.5rem;
  }

  .ang-brackets {
    font-size: 4rem;
  }

  .card-brackets {
    font-size: 1.7rem;
  }

  .card-font {
    font-size: 1rem;
  }

  .end-text {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.3rem;
    font-weight: 100;
  }
}

@media (min-width: 992px) {
  .h1-text {
    font-size: 70px;
  }
  .h3-text {
    font-size: 25px;
  }
  .brackets {
    font-size: 85px;
  }
  .brackets-text {
    font-size: 2rem;
  }
  .ang-text {
    font-size: 1.5rem;
  }
  .fun-text {
    font-size: 1.5rem;
  }

  .ang-brackets {
    font-size: 4rem;
  }
  .card-brackets {
    font-size: 2rem;
  }

  .card-font {
    font-size: 1rem;
  }

  .end-text {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.3rem;
    font-weight: 100;
  }
}

@media (min-width: 1200px) {
  .h1-text {
    font-size: 70px;
  }
  .h3-text {
    font-size: 30px;
  }
  .brackets {
    font-size: 90px;
  }
  .brackets-text {
    font-size: 2.5rem;
  }
  .ang-text {
    font-size: 1.7rem;
  }
  .fun-text {
    font-size: 1.6rem;
  }

  .ang-brackets {
    font-size: 2.5rem;
  }

  .card-brackets {
    font-size: 2rem;
  }

  .card-font {
    font-size: 1.2rem;
  }

  .end-text {
    font-size: 1.7rem;
    font-weight: bold;
  }
  .end-text-light {
    font-size: 1.5rem;
    font-weight: 100;
  }

  .text-1 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  .text-2 {
    font-size: 1.5rem;
    font-weight: 100;

  }
}

.input:focus {
  outline: none !important;
  border:1px solid #E55D3D;
  box-shadow: 0 0 10px #E55D3D;
}

.btn-outline-tags {
    color: #fe6655;
    background-color: transparent;
    background-image: none;
    border-color: #fe6655;
}
.bodyx {
    font-family: 'Raleway', sans-serif;
    background: #eee;
}

.bodyy {
    top: 100%;
    font-family: 'Raleway', sans-serif;
    background: #eee;
}
.wrapperBody {
    left: 0%;
    width: 300px;
    height: 380px;
    perspective: 900px;
    margin-left: 390px;
    align-items: center
}

@media only screen and (max-width: 750px) {
    .wrapperBody {
        left: 0%;
        width: 300px;
        height: 380px;
        perspective: 900px;
        margin-left: 100px;
        align-items: center
    }
}


.wrapperx {
    top: 10%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.wrappery {
    position: absolute;
    top: 60%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.bodyz {
    top: 150%;
    font-family: 'Lato', sans-serif;
    z-index: 0;
}

.bodyz1 {
    top: 0%;
    font-family: 'Lato', sans-serif;
    z-index: 0;
}

.wrapperz {

    position: absolute;
    top: 620px;
    left: 0%;
    width: 100%;
    height: 380px;
    perspective: 900px;
}

.containerx {
    top: 10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    transform: rotateX(30deg) scale(0.7);
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
    -webkit-animation: entryx 0.6s linear 1;
            animation: entryx 0.6s linear 1;
}

.containery {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
}

#c0x {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    z-index: 300;
    box-shadow: 0px 20px 100px #555;
}



#endx {
    box-shadow: 0px 20px 100px #555;
    z-index: 0;
}

#c1x {
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
    left: 50%;
    z-index: 0;
}

#c2x {
    left: -50%;
    z-index: 0;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
}

.containerx:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1) translateY(10px);
    transition: .5s all ease;
    z-index: 400;
}

.imagex {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 70%;
}

#i1x {
    top: 5%;
    background: linear-gradient(to bottom, #ffffff00 30%, #ffffff00 100%);
}

#i2x {
    background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);
}

.spanx {
    position: absolute;
    top: 0%;
    left: 55%;
    border-radius: 100%;
    background-color: #fff;
    z-index: 10;
}

.sunx {
    position: absolute;
    top: 50%;
    height: 50%;
    left: 0%;
    width: 100%;
}

.sun1x {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 10px #fff;
}

.st4x {
    fill: #f8c56f;
    opacity: 1;
}

.st5x,
.st7x {
    fill: #fff;
}

#sh2x {
    opacity: 0.5;
}

#sh3x {
    opacity: 0.3;
}

.st6x {
    fill: #59476f;
}

.st10x {
    fill: #c5a7e5;
    stroke: #222;
}

.st11x {
    stroke: #212121;
    opacity: 0.5;
}

#sh1x {
    fill: #f8c56f;
    opacity: 0.4;
}

.st0x {
    fill: #a25a62;
    stroke: none;
    stroke-miterlimit: 10;
}

.st1x {
    fill: none;
    stroke: #a25a62;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.st2x {
    fill: #cd775c;
}

.st3x {
    fill: #fff;
    opacity: 0.3;
}

.storyx {
    position: absolute;
    top: 55%;
    left: 0%;
    height: 55%;
    width: 100%;
    background: linear-gradient(to bottom, #eba65b 0%, #d99267 40%);
    z-index: 30;
}

#s1x {
    background: linear-gradient(to top, #eba65b 0%, #d99267 100%);
}

#s2x {
    background: linear-gradient(to top, #5b62a2 0%, #7b88d1 120%);
}

.infox {
    position: absolute;
    top: 10%;
}

.h3x {
    text-align: center;
    text-shadow: 0px 0px 10px #eee;
    color: #eee;
    letter-spacing: 2px;
}

.h4x {
    color: #111;
    position: absolute;
    top: 30%;
    left: 30%;
    letter-spacing: 2px;
}

.h1x {
    color: #111;
    position: fixed;
    top: 0%;
    left: 30%;
    letter-spacing: 2px;
}

.px {
    font-size: 14px;
    color: #ffffff00;
    padding: 0px 20px 20px 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1px;
}

.pagex {
    position: absolute;
    top: 80%;
    width: 100%;
    left: 0%;
    height: 20%;
    z-index: 30;
}

.lix {
    position: absolute;
    top: 50%;
    list-style: none;
    color: #111;
}

.lix:nth-child(1) {
    left: 15%;
    -webkit-animation: simple1x 1s linear infinite;
            animation: simple1x 1s linear infinite;
}

.lix:nth-child(2) {
    right: 15%;
    -webkit-animation: simplex 1s linear infinite;
            animation: simplex 1s linear infinite;
}

@-webkit-keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@-webkit-keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@-webkit-keyframes entryx {
    0% {
        top: -20%;
        opacity: 0.1;
    }

    100% {
        top: 10%;
    }
}

@keyframes entryx {
    0% {
        top: -20%;
        opacity: 0.1;
    }

    100% {
        top: 10%;
    }
}
.bodyx {
    font-family: 'Raleway', sans-serif;
    background: #eee;
}

.bodyy {
    top: 100%;
    font-family: 'Raleway', sans-serif;
    background: #eee;
}

.wrapperx {
    top: 10%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.wrappery {
    position: absolute;
    top: 60%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.bodyz {
    top: 150%;
    font-family: 'Raleway', sans-serif;
    z-index: 0;

}

.bodyz1 {
    top: 0%;
    font-family: 'Lato', sans-serif;
    z-index: 0;
}



.wrapperz1 {

    position: absolute;
    top: 590px;
    left: 0%;
    width: 100%;
    height: 380px;
    perspective: 900px;
}


@media only screen and (max-width: 600px) {
    
.wrapperx {
    position: absolute;
    top: 60%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
    color: "#000000"
}
    
  }

.containerx {
    top: 10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    transform: rotateX(30deg) scale(0.7);
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
    -webkit-animation: entryx 0.6s linear 1;
            animation: entryx 0.6s linear 1;
}

.containery {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
}

#c0x {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    z-index: 300;
    box-shadow: 0px 20px 100px #555;
}

#endx {
    box-shadow: 0px 20px 100px #555;
    z-index: 0;
}

#c1x {
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
    left: 50%;
    z-index: 0;
}

#c2x {
    left: -50%;
    z-index: 0;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
}

.containerxx {
    transition: .5s all ease;
    transform: rotateX(0deg) scale(1.0);

    -webkit-animation: entryx 0.6s linear 1;

            animation: entryx 0.6s linear 1;
}

.containerxx:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1.05) translateY(0px);
    transition: .5s all ease;
    z-index: 0;
}


.containerx:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1) translateY(10px);
    transition: .5s all ease;
    z-index: 400;
}

.imagex {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 70%;
}

#i1x {
    top: 5%;
    background: linear-gradient(to bottom, #ffffff00 30%, #ffffff00 100%);
}

#i2x {
    background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);
}

.spanx {
    position: absolute;
    top: 0%;
    left: 55%;
    border-radius: 100%;
    background-color: #fff;
    z-index: 10;
}

.sunx {
    position: absolute;
    top: 50%;
    height: 50%;
    left: 0%;
    width: 100%;
}

.sun1x {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 10px #fff;
}

.st4x {
    fill: #f8c56f;
    opacity: 1;
}

.st5x,
.st7x {
    fill: #fff;
}

#sh2x {
    opacity: 0.5;
}

#sh3x {
    opacity: 0.3;
}

.st6x {
    fill: #59476f;
}

.st10x {
    fill: #c5a7e5;
    stroke: #222;
}

.st11x {
    stroke: #212121;
    opacity: 0.5;
}

#sh1x {
    fill: #f8c56f;
    opacity: 0.4;
}

.st0x {
    fill: #a25a62;
    stroke: none;
    stroke-miterlimit: 10;
}

.st1x {
    fill: none;
    stroke: #a25a62;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.st2x {
    fill: #cd775c;
}

.st3x {
    fill: #fff;
    opacity: 0.3;
}

.storyx {
    position: absolute;
    top: 55%;
    left: 0%;
    height: 55%;
    width: 100%;
    background: linear-gradient(to bottom, #eba65b 0%, #d99267 40%);
    z-index: 30;
}

#s1x {
    background: linear-gradient(to top, #eba65b 0%, #d99267 100%);
}

#s2x {
    background: linear-gradient(to top, #5b62a2 0%, #7b88d1 120%);
}

.infox {
    position: absolute;
    top: 10%;
}

.h3x {
    text-align: center;
    text-shadow: 0px 0px 10px #eee;
    color: #eee;
    letter-spacing: 2px;
}

.h4x {
    color: #111;
    position: absolute;
    top: 30%;
    left: 30%;
    letter-spacing: 2px;
}

.h1x {
    color: #111;
    position: fixed;
    top: 0%;
    left: 30%;
    letter-spacing: 2px;
}

.px {
    font-size: 14px;
    color: #ffffff00;
    padding: 0px 20px 20px 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1px;
}

.pagex {
    position: absolute;
    top: 80%;
    width: 100%;
    left: 0%;
    height: 20%;
    z-index: 30;
}

.lix {
    position: absolute;
    top: 50%;
    list-style: none;
    color: #111;
}

.lix:nth-child(1) {
    left: 15%;
    -webkit-animation: simple1x 1s linear infinite;
            animation: simple1x 1s linear infinite;
}

.lix:nth-child(2) {
    right: 15%;
    -webkit-animation: simplex 1s linear infinite;
            animation: simplex 1s linear infinite;
}

@-webkit-keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@-webkit-keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@-webkit-keyframes entryx {
    0% {
        top: -20%;
        opacity: 0.1;
    }

    100% {
        top: 10%;
    }
}

@keyframes entryx {
    0% {
        top: -20%;
        opacity: 0.1;
    }

    100% {
        top: 10%;
    }
}

.bodyx {
    font-family: 'Raleway', sans-serif;
    background: #eee;
}

.bodyy {
    top: 100%;
    font-family: 'Raleway', sans-serif;
    background: #eee;
}

.wrapperx {
    top: 500px;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

@media only screen and (max-width: 750px) {
    
    .wrapperx {
        top: 720px;
        left: 40%;
        width: 300px;
        height: 380px;
        perspective: 900px;
    }
        
      }
    

.wrappery {
    position: absolute;
    top: 60%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.bodyz {
    top: 150%;
    font-family: 'Roboto';
    z-index: 0;

}

.bodyz1 {
    top: 0%;
    font-family: 'Lato', sans-serif;
    z-index: 0;
}

.wrapperz1 {

    position: absolute;
    top: 590px;
    left: 0%;
    width: 100%;
    height: 380px;
    perspective: 900px;
}

.containerx {
    position: absolute;
    top: 5%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    transform: rotateX(20deg) scale(0.8);
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
    -webkit-animation: entryx 0.6s linear 1;
            animation: entryx 0.6s linear 1;
    border-radius: 30px;
    border-color: #007bff;
    border-width: 1px;
    border-style: solid;
}

.containery {
    position: absolute;
    top: -10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
}

#c0x {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    z-index: 300;
    box-shadow: 0px 20px 100px #555;
}

#endx {
    box-shadow: 0px 20px 100px #555;
    z-index: 0;
}

#c1x {
    background: #ffffff;
    box-shadow: 0px 20px 50px #555;
    left: 50%;
    z-index: 0;
}

#c2x {
    background: #ffffff;
    box-shadow: 0px 20px 50px #555;
    left: -50%;
    z-index: 0;
}

.containerxx {
    transition: .5s all ease;
    transform: rotateX(0deg) scale(1.0);

    -webkit-animation: entryx 0.6s linear 1;

            animation: entryx 0.6s linear 1;
}

.containerxx:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1.05) translateY(0px);
    transition: .5s all ease;
    z-index: 0;
}


.containerx:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1) translateY(20px);
    transition: .5s all ease;
    z-index: 400;
}

.imagex {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 70%;
}

#i1x {
    top: 5%;
    background: linear-gradient(to bottom, #ffffff00 30%, #ffffff00 100%);
}

#i2x {
    background: #fff;
}

.spanx {
    position: absolute;
    top: 0%;
    left: 55%;
    border-radius: 100%;
    background-color: #fff;
    z-index: 10;
}

.sunx {
    position: absolute;
    top: 50%;
    height: 50%;
    left: 0%;
    width: 100%;
}

.sun1x {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 10px #fff;
}

.st4x {
    fill: #f8c56f;
    opacity: 1;
}

.st5x,
.st7x {
    fill: #fff;
}

#sh2x {
    opacity: 0.5;
}

#sh3x {
    opacity: 0.3;
}

.st6x {
    fill: #59476f;
}

.st10x {
    fill: #c5a7e5;
    stroke: #222;
}

.st11x {
    stroke: #212121;
    opacity: 0.5;
}

#sh1x {
    fill: #f8c56f;
    opacity: 0.4;
}

.st0x {
    fill: #a25a62;
    stroke: none;
    stroke-miterlimit: 10;
}

.st1x {
    fill: none;
    stroke: #a25a62;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.st2x {
    fill: #cd775c;
}

.st3x {
    fill: #fff;
    opacity: 0.3;
}

.storyx {
    position: absolute;
    color: #000;
    z-index: 30;
}

#s1x {
    background: #ffffff00;
    color: #000;

}

#s2x {
    background: #ffffff00;
    color: #000;
}

.infox {
    position: absolute;
    top: 10%;
}

.h3x {
    text-align: center;
    text-shadow: 0px 0px 10px #eee;
    color: #000;
    letter-spacing: 2px;
}

.h4x {
    color: #111;
    position: absolute;
    top: 30%;
    left: 30%;
    letter-spacing: 2px;
}

.h1x {
    color: #111;
    position: fixed;
    top: 0%;
    left: 30%;
    letter-spacing: 2px;
}

.px {
    font-size: 14px;
    color: #000;
    background-color: #ffffff00;
    padding: 0px 20px 20px 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1px;
}

.pagex {
    position: absolute;
    top: 80%;
    width: 100%;
    left: 0%;
    height: 20%;
    z-index: 30;
}

.lix {
    position: absolute;
    top: 50%;
    list-style: none;
    color: #111;
}

.lix:nth-child(1) {
    left: 15%;
    -webkit-animation: simple1x 1s linear infinite;
            animation: simple1x 1s linear infinite;
}

.lix:nth-child(2) {
    right: 15%;
    -webkit-animation: simplex 1s linear infinite;
            animation: simplex 1s linear infinite;
}

@-webkit-keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@-webkit-keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@-webkit-keyframes entryx {
    0% {
        top: 20%;
        opacity: 0.1;
    }

    100% {
        top: 5%;
    }
}

@keyframes entryx {
    0% {
        top: 20%;
        opacity: 0.1;
    }

    100% {
        top: 5%;
    }
}
.bodyPP {
  margin: 0px;
}
.containerPP {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow:hidden;
}

.panelPP {    
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerPP {
  background: lightblue;
  min-height: 50px;
  height: 100%;
}
.footerPP {
  background: lightblue;
  height: 100%;
  display:flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: center;
  justify-content: flex-start;
  overflow: hidden;
}
.footerAreaPP {
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-flow: column;
  overflow-y: auto;
}
.footerAreaContentPP {
  min-height: 500px;
}
.footerBottomBarPP {
  background: blue;
  color: white;
  padding: 10px
}
.bodyPP {
  background: gray;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}

.sideBarResizeContainerPP {
  width: 30%;
}
.sidebarPP {
  background: #263238;
  min-width: 200px;
  width: 400px;
  width:100%;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
}


.withMarginPP {
  margin: 2px;
  box-sizing: border-box;
  
}

.contentPP {
  flex-grow: 2;  
  background: #263238; 
  color:white;
  height:100%;
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: 0px -5px;
  background: lightblue;
  border: 2px solid gray;
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center ;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 5px;
  background: gray;
  display: flex;
  z-index: 99999;
  align-items: center ;
  align-content: center ;
  justify-content: center;
  overflow: visible;

}
