@import url('https://fonts.googleapis.com/css?family=Raleway:200');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.bodyx {
    font-family: 'Raleway', sans-serif;
    background: #eee;
}

.bodyy {
    top: 100%;
    font-family: 'Raleway', sans-serif;
    background: #eee;
}
.wrapperBody {
    left: 0%;
    width: 300px;
    height: 380px;
    perspective: 900px;
    margin-left: 390px;
    align-items: center
}

@media only screen and (max-width: 750px) {
    .wrapperBody {
        left: 0%;
        width: 300px;
        height: 380px;
        perspective: 900px;
        margin-left: 100px;
        align-items: center
    }
}


.wrapperx {
    top: 10%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.wrappery {
    position: absolute;
    top: 60%;
    left: 40%;
    width: 300px;
    height: 380px;
    perspective: 900px;
}

.bodyz {
    top: 150%;
    font-family: 'Lato', sans-serif;
    z-index: 0;
}

.bodyz1 {
    top: 0%;
    font-family: 'Lato', sans-serif;
    z-index: 0;
}

.wrapperz {

    position: absolute;
    top: 620px;
    left: 0%;
    width: 100%;
    height: 380px;
    perspective: 900px;
}

.containerx {
    top: 10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    transform: rotateX(30deg) scale(0.7);
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
    animation: entryx 0.6s linear 1;
}

.containery {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    perspective: 900px;
    box-shadow: 0px 20px 50px #555;
}

#c0x {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    z-index: 300;
    box-shadow: 0px 20px 100px #555;
}



#endx {
    box-shadow: 0px 20px 100px #555;
    z-index: 0;
}

#c1x {
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
    left: 50%;
    z-index: 0;
}

#c2x {
    left: -50%;
    z-index: 0;
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
}

.containerx:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1) translateY(10px);
    transition: .5s all ease;
    z-index: 400;
}

.imagex {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 70%;
}

#i1x {
    top: 5%;
    background: linear-gradient(to bottom, #ffffff00 30%, #ffffff00 100%);
}

#i2x {
    background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);
}

.spanx {
    position: absolute;
    top: 0%;
    left: 55%;
    border-radius: 100%;
    background-color: #fff;
    z-index: 10;
}

.sunx {
    position: absolute;
    top: 50%;
    height: 50%;
    left: 0%;
    width: 100%;
}

.sun1x {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 10px #fff;
}

.st4x {
    fill: #f8c56f;
    opacity: 1;
}

.st5x,
.st7x {
    fill: #fff;
}

#sh2x {
    opacity: 0.5;
}

#sh3x {
    opacity: 0.3;
}

.st6x {
    fill: #59476f;
}

.st10x {
    fill: #c5a7e5;
    stroke: #222;
}

.st11x {
    stroke: #212121;
    opacity: 0.5;
}

#sh1x {
    fill: #f8c56f;
    opacity: 0.4;
}

.st0x {
    fill: #a25a62;
    stroke: none;
    stroke-miterlimit: 10;
}

.st1x {
    fill: none;
    stroke: #a25a62;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.st2x {
    fill: #cd775c;
}

.st3x {
    fill: #fff;
    opacity: 0.3;
}

.storyx {
    position: absolute;
    top: 55%;
    left: 0%;
    height: 55%;
    width: 100%;
    background: linear-gradient(to bottom, #eba65b 0%, #d99267 40%);
    z-index: 30;
}

#s1x {
    background: linear-gradient(to top, #eba65b 0%, #d99267 100%);
}

#s2x {
    background: linear-gradient(to top, #5b62a2 0%, #7b88d1 120%);
}

.infox {
    position: absolute;
    top: 10%;
}

.h3x {
    text-align: center;
    text-shadow: 0px 0px 10px #eee;
    color: #eee;
    letter-spacing: 2px;
}

.h4x {
    color: #111;
    position: absolute;
    top: 30%;
    left: 30%;
    letter-spacing: 2px;
}

.h1x {
    color: #111;
    position: fixed;
    top: 0%;
    left: 30%;
    letter-spacing: 2px;
}

.px {
    font-size: 14px;
    color: #ffffff00;
    padding: 0px 20px 20px 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1px;
}

.pagex {
    position: absolute;
    top: 80%;
    width: 100%;
    left: 0%;
    height: 20%;
    z-index: 30;
}

.lix {
    position: absolute;
    top: 50%;
    list-style: none;
    color: #111;
}

.lix:nth-child(1) {
    left: 15%;
    animation: simple1x 1s linear infinite;
}

.lix:nth-child(2) {
    right: 15%;
    animation: simplex 1s linear infinite;
}

@keyframes simplex {
    50% {
        transform: translateX(10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

@keyframes simple1x {
    50% {
        transform: translateX(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@keyframes entryx {
    0% {
        top: -20%;
        opacity: 0.1;
    }

    100% {
        top: 10%;
    }
}