.bodyPP {
  margin: 0px;
}
.containerPP {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow:hidden;
}

.panelPP {    
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerPP {
  background: lightblue;
  min-height: 50px;
  height: 100%;
}
.footerPP {
  background: lightblue;
  height: 100%;
  display:flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: center;
  justify-content: flex-start;
  overflow: hidden;
}
.footerAreaPP {
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-flow: column;
  overflow-y: auto;
}
.footerAreaContentPP {
  min-height: 500px;
}
.footerBottomBarPP {
  background: blue;
  color: white;
  padding: 10px
}
.bodyPP {
  background: gray;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}

.sideBarResizeContainerPP {
  width: 30%;
}
.sidebarPP {
  background: #263238;
  min-width: 200px;
  width: 400px;
  width:100%;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
}


.withMarginPP {
  margin: 2px;
  box-sizing: border-box;
  
}

.contentPP {
  flex-grow: 2;  
  background: #263238; 
  color:white;
  height:100%;
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: 0px -5px;
  background: lightblue;
  border: 2px solid gray;
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center ;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 5px;
  background: gray;
  display: flex;
  z-index: 99999;
  align-items: center ;
  align-content: center ;
  justify-content: center;
  overflow: visible;

}